<template>
  <div class="promo-codes">
    <Card>
      <FormulateForm
        v-model="formData"
        name="promoForm"
        @submit="tierSubmit"
        #default="{ isLoading }"
        autocomplete="false"
      >
        <div class="row row-container">
          <div class="col half">
            <FormulateInput
              type="text"
              label="Promo Code"
              placeholder="SAVE10"
              id="promoName"
              validation="required"
              v-model="promoName"
              :error="errorPromoNameDisplay"
              :readOnly="editing"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              class="code-description"
              type="text"
              validation="required"
              label="Code Description"
              :placeholder="codeDescriptionPlaceholderDisplay"
              v-model="codeDescription"
              :error="errorCodeDiscriptionDisplay"
              autocomplete="false"
            />
          </div>
        </div>
        <div class="row row-container">
          <div class="col half radio-button-container">
            <FormulateInput
              label="Select Discount Method"
              type="radio"
              :options="methods"
              v-model="discountType"
              validation="required"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              class="discount"
              type="number"
              step="0.01"
              label="Discount"
              min="0"
              @wheel="$event.target.blur()"
              :placeholder="discountPlaceholderDisplay"
              validation="required"
              v-model="discount"
              :error="errorDiscountDisplay"
              v-on:input="checkDiscountTypeInput"
              autocomplete="false"
            />
          </div>
        </div>
        <div class="row" v-if="multiselectOptions.length">
          <div class="col half">
            <FormulateInput
              type="multiSelectGroup"
              label="Guest list Sections/Bottles/Foods"
              :placeholder="'Select options'"
              :options="multiselectOptions"
              v-model="guestList"
              :validation="guestListValidation"
              groupLabelField="group-label"
              groupValuesField="options"
            />
          </div>
          <div class="col half">
            <TicketTierSelector
              v-if="isRecurringEvent"
              :error="false"
              :tiers="eventTiers"
              :hasAllTiers="true"
              v-model="tier"
              @input="addTier($event)"
            />
            <FormulateInput
              v-else
              type="multiSelect"
              class="dropdown ticket-tier"
              label="Ticket Tier"
              placeholder="Select Ticket Tier"
              :validation="tierValidation"
              :options="tiers"
              v-model="ticketTiers"
              :error="errorTicketTierDisplay"
              autocomplete="false"
            />
          </div>
        </div>
        <div class="row row-container">
          <div class="col half">
            <FormulateInput
              class="number-codes"
              type="number"
              validation="required"
              label="Limit Per User"
              placeholder="1"
              min="0"
              step="1"
              @wheel="$event.target.blur()"
              v-model="limitPerUser"
              :error="errorNumCodesDisplay"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              class="number-codes"
              type="number"
              validation="required"
              label="Number of Codes Available"
              placeholder="50"
              min="0"
              step="1"
              @wheel="$event.target.blur()"
              v-model="numOfCodes"
              :error="errorNumCodesDisplay"
              autocomplete="false"
            />
          </div>
        </div>

        <div class="row row-container">
          <div class="col half">
            <FormulateInput
              type="dateTime"
              class="date-container"
              v-model="promoStartDate"
              label="Start Date"
              :placeholder="dateDisplayPlaceholder"
              validation="required"
              :min-date="new Date()"
              :max-date="startDateMaximumDate"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              class="date-container"
              type="dateTime"
              v-model="promoEndDate"
              label="End Date"
              validation="required"
              :placeholder="dateDisplayPlaceholder"
              :min-date="endDateMinimumDate"
              :max-date="eventFinalDate"
              autocomplete="false"
            />
          </div>
        </div>
        <div class="buttons" v-if="editing">
          <div>
            <diyobo-button
              class="cancel-button"
              @click="cancelEdit"
              type="primary-dark"
              txt="Cancel"
            />
          </div>
          <div>
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :name="isLoading ? 'Saving...' : 'Save'"
            />
          </div>
        </div>

        <div class="buttons" v-else>
          <div>
            <diyobo-button
              class="back-events"
              @click="redirect"
              type="primary-dark"
              txt="Back to My Events"
            />
          </div>
          <div>
            <FormulateInput
              type="submit"
              :disabled="isLoading"
              :name="isLoading ? 'Submitting...' : 'Submit'"
            />
          </div>
        </div>
      </FormulateForm>
    </Card>
    <div class="divider"></div>

    <div class="row">
      <div class="col full">
        <table-tote v-model="tableData"/>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.radio-button-container {
  .formulate-input-element {
    display: flex;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;

  .back-events {
    height: 46px;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.formulate-input {
  &[data-classification="submit"] button {
    margin-left: initial;
  }
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import TableTote from "@/components/TableTote.vue";
import RadioGroup from "@/components/RadioGroup.vue";
import Vue from "vue";
import dateFormat from "dateformat";
import ModalContent from "@/helpers/modals";
import DiyoboCalendar from "../../components/diyoboCalendar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import TicketTierSelector from "@/components/TicketTierSelector.vue";
import TabTote from "@/components/TabTote.vue";
import dayjs from "dayjs";

const PROMO_DISCOUNT = "Promo Discount";
const BULK_DISCOUNT = "Bulk Discount";
const BUNDLE_DISCOUNT = "Bundled Discount";
const ALL_TICKET_TIERS = "All Ticket Tiers";

const DOLLAR_DISCOUNT = "$ Discount";
const PERCENT_DISCOUNT = "% Discount";

export default {
  name: "promo-codes",
  components: {
    DiyoboInput,
    DiyoboButton,
    TableTote,
    TabTote,
    RadioGroup,
    DiyoboCalendar,
    Breadcrumbs,
    TicketTierSelector
  },
  head() {
    return {
      title: "Promo Codes & Discounts"
    };
  },
  data() {
    return {
      formData: null,
      eventFinalDate: "",
      dateDisplayPlaceholder: dateFormat(
        new Date(Date.now()),
        "ddd, mmm dS, yyyy"
      ),
      multiselectOptions: [],
      tierList: [{value: ALL_TICKET_TIERS, label: ALL_TICKET_TIERS}],
      bundleTableDatas: {},
      bundleTableIndex: 0,
      bulkTableDatas: {},
      bulkTableIndex: 0,
      categoryDiscountTypeButtons: [
        {
          id: "promo-discount",
          name: "category-discount",
          label: PROMO_DISCOUNT,
          checked: true
        },
        {
          id: "bulk-discount",
          name: "category-discount",
          label: BULK_DISCOUNT,
          checked: false
        },
        {
          id: "bundled-discount",
          name: "category-discount",
          label: BUNDLE_DISCOUNT,
          checked: false
        }
      ],
      endDate: "",
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Promo Codes & Discounts", null]
      ],
      tiers: [],
      methods: [DOLLAR_DISCOUNT, PERCENT_DISCOUNT],
      saleDateArr: [],
      eventUrl: this.$route.params.url,
      bus: new Vue(),
      promoName: "",
      discountType: DOLLAR_DISCOUNT,
      ticketTier: null,
      ticketTiers: [],
      guestList: [],
      promoStartDate: "",
      promoStartDateMin: "",
      codeDescription: "",
      discount: "",
      numOfCodes: "",
      limitPerUser: "",
      promoEndDate: "",
      dateInputID: "",
      saleDateRefArray: [],
      bus5: new Vue(),
      eventTiers: [],
      tier: null,
      errors: [
        {name: "promoName", error: false, msg: "Promo is required"},
        {
          name: "discountTypeButtons",
          error: false,
          msg: "Discount type is required"
        },
        {name: "ticketTier", error: false, msg: "Ticket tier is required"},
        {
          name: "promoStartDate",
          error: false,
          msg: "Promo start date is required"
        },
        {
          name: "codeDescription",
          error: false,
          msg: "Description is required"
        },
        {
          name: "discount",
          error: false,
          msg: "Discount is required"
        },
        {name: "numOfCodes", error: false, msg: "Quantity is required"},
        {
          name: "promoEndDate",
          error: false,
          msg: "Promo end date is required"
        },
        {
          name: "limitPerUser",
          error: false,
          msg: "Limit per user is required"
        }
        // { name: "validDate", error: false, msg: ' is required' },
        // { name: "Sale Date", error: false, msg: 'Promo is required' }
      ],
      errorMsg: [],
      errorsToCorrect: [],
      title: "",
      tableData: {},
      maxInt: 15,
      editing: false,
      isRecurringEvent: false,
      venueAccessDates: []
    };
  },
  computed: {
    guestListValidation() {
      return this.eventTiers.length == 0 ? "required" : "";
    },
    tierValidation() {
      return this.guestList && this.guestList.length ? "" : "required";
    },
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("promo-codes")) {
        return 0;
      } else if (path.endsWith("bulk-discounts")) {
        return 1;
      } else if (path.endsWith("bundle-discounts")) {
        return 2;
      }
    },
    startDateMaximumDate() {
      return this.promoEndDate !== "" ? this.promoEndDate : this.eventFinalDate;
    },
    endDateMinimumDate() {
      return this.promoStartDate === "" || this.promoStartDate === null
        ? new Date()
        : this.promoStartDate;
    },
    errorStartDateDisplay() {
      return this.errors[3].error ? this.errors[3].msg : "";
    },
    errorPromoNameDisplay() {
      return this.errors[0].error ? this.errors[0].msg : "";
    },
    errorTicketTierDisplay() {
      return this.errors[2].error ? this.errors[2].msg : "";
    },
    errorCodeDiscriptionDisplay() {
      return this.errors[4].error ? this.errors[4].msg : "";
    },
    errorDiscountDisplay() {
      return this.errors[5].error ? this.errors[5].msg : "";
    },
    errorNumCodesDisplay() {
      return this.errors[6].error ? this.errors[6].msg : "";
    },
    errorEndDateDisplay() {
      return this.errors[7].error ? this.errors[7].msg : "";
    },

    promoCategoryDiscountTypeDisplay() {
      return this.categoryDiscountTypeButtons[0].checked;
    },
    bulkCategoryDiscountTypeDisplay() {
      return this.categoryDiscountTypeButtons[1].checked;
    },
    bundleCategoryDiscountTypeDisplay() {
      return this.categoryDiscountTypeButtons[2].checked;
    },
    discountPlaceholderDisplay() {
      return this.discountType === DOLLAR_DISCOUNT
        ? "Discount ($)"
        : "Discount (%)";
    },
    codeDescriptionPlaceholderDisplay() {
      if (this.discountType === DOLLAR_DISCOUNT) {
        return "This code gives you $10 off!";
      } else {
        if (this.discount > 100) {
          this.discount = 100;
          this.errors[5].error = false;
        }
        return "This code gives you 10% off!";
      }
    }
  },
  methods: {
    addTier(data) {
      if (data !== undefined) {
        this.ticketTier = data.name;
      }
    },
    onClickNavTab(index) {
      const path = this.$route.path;
      const url = this.$route.params.url;
      if (index === 0 && !path.endsWith("promo-codes")) {
        this.$router.push(`/discounts/${url}/promo-codes`);
      } else if (index === 1 && !path.endsWith("bulk-discounts")) {
        this.$router.push(`/discounts/${url}/bulk-discounts`);
      } else if (index === 2 && !path.endsWith("bundle-discounts")) {
        this.$router.push(`/discounts/${url}/bundle-discounts`);
      }
    },
    async addPromo() {
      // let jsonData = require("../assets/results.json");
      // for (let i = 0; i < jsonData.length; i++) {
      // const element = jsonData[0];
      // this.$axios
      //   .post("/planners/add-promo-codes", {
      //     promo_codes: {
      //       promoName: element.promoName,
      //       discountType: element.discountType,
      //       ticketTier: element.ticketTier,
      //       codeDescription: element.codeDescription,
      //       discount: element.discount,
      //       remaining: element.remaining,
      //       count: element.count,
      //       promoStartDate: element.promoStartDate,
      //       promoEndDate: element.promoEndDate,
      //     },
      //     url: this.eventUrl,
      //   })
      //   .then((response) => {});
      // }
      jsonData.forEach(element => {
        this.$axios
          .post("/planners/add-promo-codes", {
            promo_codes: {
              promoName: element.promoName,
              discountType: element.discountType,
              ticketTier: element.ticketTier,
              guestList: element.guestList,
              codeDescription: element.codeDescription,
              discount: element.discount,
              remaining: element.remaining,
              count: element.count,
              promoStartDate: element.promoStartDate,
              promoEndDate: element.promoEndDate
            },
            url: this.eventUrl
          })
          .then(response => {
          });
      });
    },
    tierSubmit() {
      let self = this;
      this.errorMsg = [];
      this.errorsToCorrect = [];

      // if (self.ticketTier === "") {
      //   self.errors[2].error = true;
      // } else {
      //   self.errors[2].error = false;
      // }
      // if (self.promoStartDate === "" || self.promoStartDate === null) {
      //   self.errors[3].error = true;
      // } else {
      //   self.errors[3].error = false;
      // }
      if (self.codeDescription == "") {
        self.errors[4].error = true;
      } else {
        self.errors[4].error = false;
      }
      if (self.discount === "") {
        self.errors[5].msg = "Discount is required";
        self.errors[5].error = true;
      } else {
        self.errors[5].error = false;
        // if (self.ticketTiers !== "") {
        //   if (self.ticketTier === "All Ticket Tiers") {
        //     for (let x = 0; x < self.eventTiers.length; x++) {
        //       let tempBool = false;
        //       if (
        //         parseInt(self.discount) > self.eventTiers[x].price &&
        //         self.discountType == "$ Discount"
        //       ) {
        //         self.errors[5].error = true;
        //         tempBool = true;
        //         self.errors[5].msg = `This exceeds the price of $${self.eventTiers[x].price}`;
        //       } else {
        //         self.errors[5].error = false;
        //       }
        //       if (tempBool === false) {
        //         self.errors[5].error = false;
        //       }
        //     }
        //   } else {
        //     for (let x = 0; x < self.eventTiers.length; x++) {
        //       if (self.ticketTier == self.eventTiers[x].name) {
        //         if (
        //           parseInt(self.discount) > self.eventTiers[x].price &&
        //           self.discountType == "$ Discount"
        //         ) {
        //           self.errors[5].error = true;
        //           self.errors[5].msg = `This exceeds the price of $${self.eventTiers[x].price}`;
        //         } else {
        //           self.errors[5].error = false;
        //         }
        //       }
        //     }
        //   }
        // }
      }
      if (self.numOfCodes === "") {
        self.errors[6].error = true;
        self.errors[6].msg = "Quantity is required";
      } else {
        //   let totalQty = 0;
        //   if (self.ticketTier !== "") {
        //     if (self.ticketTier === ALL_TICKET_TIERS) {
        //       for (const tier of self.eventTiers) {
        //         let {saleDates} = tier;
        //         for (const sale of saleDates) {
        //           totalQty += sale.qty;
        //         }
        //       }
        //     } else {
        //       const eventTier = self.eventTiers.find(
        //           tier => tier.name === self.ticketTier
        //       );
        //       let {saleDates} = eventTier;
        //       for (const sale of saleDates) {
        //         totalQty += sale.qty;
        //       }
        //     }
        //   }
        //   if (self.numOfCodes > totalQty && totalQty > 0) {
        //     self.errors[6].error = true;
        //     self.errors[6].msg = `This exceeds the quantity available of ${totalQty}`;
        //   } else {
        //     self.errors[6].error = false;
        //   }
      }
      if (self.limitPerUser === '') {
        self.errors[8].error = true;
        self.errors[8].msg = "Quantity is required";
      }
      let start = new Date(this.promoStartDate);
      let end = new Date(this.promoEndDate);
      if (self.promoEndDate == "" || self.promoEndDate === null) {
        self.errors[7].error = true;
      } else {
        self.errors[7].error = false;
        if (
          this.promoStartDate != "" &&
          this.promoEndDate != "" &&
          start > end
        ) {
          self.errors[7].error = true;
        } else {
          self.errors[7].error = false;
        }
      }
      // if(!self.dateInputID) {
      //     self.errors[8].error = true;
      // } else {
      //     self.errors[8].error = false;
      // }
      this.errors.forEach((element, index) => {
        if (element.error == true) {
          this.errorsToCorrect.push(element);
        }
      });
      if (this.errorsToCorrect.length == 0) {
        this.$loader.start();
        const index = this.tableData.data.findIndex(
          t => t.name === this.promoName
        );
        const endpoint = this.editing
          ? "/planners/update-promo-code"
          : "/planners/add-promo-codes";
        const count = this.editing
          ? this.tableData.data[index].count +
          (this.numOfCodes - this.tableData.data[index].remaining)
          : this.numOfCodes;
        this.$axios
          .post(endpoint, {
            promo_codes: {
              promoName: this.promoName,
              discountType: this.discountType,
              ticketTier: this.ticketTier,
              tiers: this.ticketTiers,
              guestList: this.guestList,
              // -> start: TicketTierSelector implimentation
              // ticketTier: this.tier.name,
              tierType: this.tier ? this.tier.type : null,
              tierID: this.tier ? this.tier.tierID : null,
              venueAccessDateID: this.tier ? this.tier.venueAccessDateID : null,
              venueAccessTimeID: this.tier ? this.tier.venueAccessTimeID : null,
              // -> end
              codeDescription: this.codeDescription,
              discount: this.discount,
              remaining: this.numOfCodes,
              limitPerUser: this.limitPerUser,
              count,
              promoStartDate: start.getTime(),
              promoEndDate: end.getTime(),
              active: !this.editing || this.tableData.data[index].active
            },
            url: this.eventUrl
          })
          .then(response => {
            let data = response.data;

            if (!data.error) {
              if (response.data.name) {
                let type = response.data.type.split(" ")[0];
                let newPromoCode = response.data;
                if (type == "$") {
                  newPromoCode.value = newPromoCode.value.toString();
                } else if (type == "%") {
                  newPromoCode.value =
                    newPromoCode.value.toString() + type;
                }
                newPromoCode.start_display = dateFormat(
                  new Date(newPromoCode.start_date),
                  "mmm dS, yyyy"
                );
                newPromoCode.end_display = dateFormat(
                  new Date(newPromoCode.end_date),
                  "mmm dS, yyyy"
                );
                newPromoCode.available_display =
                  newPromoCode.remaining + "/" + newPromoCode.count;

                //     // if (newPromoCode.tier_date && newPromoCode.tier_date.$date) {
                //     //   if (
                //     //     newPromoCode.tier_type === "Date" ||
                //     //     newPromoCode.tier_type === "Date and Time"
                //     //   ) {
                //     //     newPromoCode.ticket_date = dayjs(
                //     //       +newPromoCode.tier_date.$date.$numberLong
                //     //     ).format("MMM DD, YYYY");
                //     //   }

                //     //   if (newPromoCode.tier_type === "Date and Time") {
                //     //     newPromoCode.ticket_time = dayjs(
                //     //       +newPromoCode.tier_date.$date.$numberLong
                //     //     ).format("h:mm A");
                //     //   }
                //     // }

                // makes it so the table callback will pass error check
                newPromoCode.edit = true;
                newPromoCode.categoryName = PROMO_DISCOUNT;
                newPromoCode.guest_list_count = newPromoCode.guest_list ? newPromoCode.guest_list.length : 0;
                if(  newPromoCode.tiers) {
                  newPromoCode.tier = '';
                  if (newPromoCode.tiers.length == this.tiers.length) {
                    newPromoCode.tier = "All Ticket Tiers";
                  } else {
                    newPromoCode.tiers.forEach((tier, key) => {
                      let t = this.tiers.find((x) => x.value == tier);
                      if (t) {
                        newPromoCode.tier += t.label;
                        if (key + 1 < newPromoCode.tiers.length) {
                          newPromoCode.tier += ' , ';
                        }
                      }
                    });
                  }
                }

                if (this.editing) {
                  this.tableData.data.splice(index, 1, newPromoCode);
                  this.editing = false;
                  this.$store.state.bus.$emit(
                    "alert",
                    ModalContent.promoCodeUpdated
                  );
                } else {
                  this.tableData.data.push(newPromoCode);
                  this.$store.state.bus.$emit(
                    "alert",
                    ModalContent.promoCodeAdded
                  );
                }
              }
            } else {
              this.$bus.$emit("alert", {
                title: data.error.type,
                msg: data.error.message
              });
            }
            this.clear();

            this.$loader.stop();
          })
          .catch(error => {
            if (error) {
              console.log(error);
              this.$bus.$emit("alert", {
                title: error.response.data.error.type || "Error",
                msg:
                  error.response.data.error.message ||
                  "An unknown error occurred. Please try again later."
              });
            }

            this.$loader.stop();
          });
      }
    },
    redirect() {
      this.$router.push("/my-events");
    },
    updatePromoStartDate(e) {
      let date = e;
      this.promoStartDate = date;
      this.promoStartDateMin = date;
    },
    updatePromoEndDate(e) {
      let eventEndDate = new Date(parseInt(this.endDate));
      let newDate = Date.parse(e);
      if (newDate > eventEndDate) {
        this.errors[7].error = true;
      } else {
        this.errors[7].error = false;
        let date = e;
        this.promoEndDate = date;
      }
    },
    getDiscountType(discountName) {
      this.discountType = discountName;
      if (discountName == "% Discount") {
        this.maxInt = 3;
      } else {
        this.maxInt = 15;
      }
    },
    clear() {
      this.$formulate.reset("promoForm");
      this.ticketTier = "";
      this.ticketTiers = [];
      this.promoName = "";
      this.promoStartDate = "";
      this.promoStartDateMin = dateFormat(new Date(), "dddd, mmmm d, yyyy");
      this.codeDescription = "";
      this.discount = "";
      this.discountType = DOLLAR_DISCOUNT;
      this.numOfCodes = "";
      this.promoEndDate = "";
      this.tier = "";
      this.formData = null;
      this.guestList = [];

      this.bus.$emit("replace-placeholder", "Select Ticket Tier");
    },
    edit(code, field) {
      this.getCategoryDiscountTypeButtons(code.categoryName);
      switch (code.categoryName) {
        case PROMO_DISCOUNT:
          this.errors.forEach(e => (e.error = false));
          this.editing = true;
          this.promoName = code.name;
          this.discountType = code.type;
          this.ticketTier = code.tier;
          this.tier = {
            name: code.tier,
            type: code.tier_type,
            tierID: code.tier_id,
            venueAccessDateID: code.venue_access_date_id,
            venueAccessTimeID: code.venue_access_time_id
          };
          if (code.tiers) {
            this.ticketTiers = code.tiers;
          } else {
            if (code.tier == 'All Ticket Tiers') {
              this.tiers.forEach((t) => {
                this.ticketTiers.push(t.value);
              })
            } else {
              this.tiers.forEach((t) => {
                if (t.value == code.tier_id) {
                  this.ticketTiers.push(t.value);
                }
              })
            }
          }
          (this.guestList = code.guest_list),
            (this.codeDescription = code.description);
          this.discount = code.value.replace(/[\$\%]/g, "");
          this.numOfCodes = code.remaining;
          this.limitPerUser = code.limitPerUser;
          this.promoStartDate = dateFormat(
            code.start_date,
            "dddd, mmmm d, yyyy, HH:MM:ss"
          );
          this.promoEndDate = dateFormat(
            code.end_date,
            "dddd, mmmm d, yyyy, HH:MM:ss"
          );
          this.bus.$emit("set-value-placeholder", {
            placeholder: code.tier,
            value: code.tier
          });
          break;
        case BULK_DISCOUNT:
          this.$refs.bulkComponent.edit(code);
          break;
        case BUNDLE_DISCOUNT:
          this.$refs.bundleComponent.edit(code);
          break;
        default:
          alert("cannot edit please reload");
      }
      window.scrollTo({top: 10, behavior: "smooth"});
    },
    cancelEdit() {
      this.editing = false;
      this.clear();
    },
    checkDiscountTypeInput(e) {
      if (parseInt(e) > 100 && this.discountType == "% Discount") {
        this.discount = "";
        this.discount = 100;
      }
    },
    getCategoryDiscountTypeButtons(categoryDiscountLabel) {
      this.categoryDiscountTypeButtons.forEach(category => {
        if (category.label === categoryDiscountLabel) {
          category.checked = true;
        } else {
          category.checked = false;
        }
      });
    },
    incrementBulkTableIndex() {
      this.bulkTableIndex++;
    },
    incrementBundleTableIndex() {
      this.bundleTableIndex++;
    },
    getProductNameById(productList, bundleProducts) {
      let products = productList.filter(({product_id: id1}) =>
        bundleProducts.some(({productId: id2}) => id2 === id1.$oid)
      );

      let productsString = products.map(p => p.title).join(", ");
      return productsString;
    },
    getTierNameById(tierId) {
      let tier = this.tierList.find(t => t.value === tierId);
      return tier.label;
    },
    getTiers(bundleTiers) {
      let tiers = this.tierList.filter(({value: id1}) =>
        bundleTiers.some(({saleDateId: id2}) => id2 === id1)
      );

      let tiersString = tiers.map(p => p.label).join(", ");
      return tiersString;
    },
    async updatePromoCode(code, field) {
      await this.$axios.post("/planners/update-promo-code-active", {
        event: this.eventUrl,
        code: {
          name: code.name,
          field,
          value: code[field]
        }
      });
    }
  },
  async mounted() {
    let sections = [];
    let foods = [];
    let bottles = [];
    this.$axios
      .post("/planners/promo-codes", {
        url: this.eventUrl
      })
      .then(response => {
        let data = response.data;
        this.eventFinalDate = new Date(
          parseInt(data.endDate.$date.$numberLong)
        );
        response.data.sections.forEach(s => {
          sections.push({label: s.name, value: s._id});
        });
        response.data.foods.forEach(s => {
          foods.push({label: s.name, value: s._id});
        });
        response.data.bottles.forEach(s => {
          bottles.push({label: s.name, value: s._id});
        });
        this.isRecurringEvent = response.data.isRecurring;
        this.eventTiers = response.data.tiers;
        // this.tiers.push({
        //   label: "All Ticket Tiers",
        //   value: "All Ticket Tiers"
        // });
        this.endDate = data.endDate.$date.$numberLong;
        this.title = data.title;
        data.tiers.forEach(t => {
          if (t.price > 0 && t.ticketType !== "DONATION") {
            let name = t.name;
            const saleDates = t.saleDates.filter(
              (d) =>
                dayjs(+d.sale_start.$date.$numberLong).isBefore(dayjs()) &&
                dayjs(+d.sale_end.$date.$numberLong).isAfter(dayjs()) &&
                d.available > 0
            );
            if (saleDates.length) {
              name += ' (Active)'
            }
            this.tiers.push({label: name, value: t.id});
          }
        });
        const codes = data.promo_codes.map(p => {
          let type = p.type.split(" ")[0];
          if (type == "$") {
            p.value = p.value.toString();
          } else if (type == "%") {
            p.value = p.value.toString() + type;
          }
          p.start_display = dateFormat(
            new Date(p.start_date),
            "mmm dS, yyyy"
          );
          p.end_display = dateFormat(
            new Date(p.end_date),
            "mmm dS, yyyy"
          );
          p.guest_list_count = p.guest_list ? p.guest_list.length : 0;
          if (p.tiers) {
            p.tier = '';
            if (p.tiers.length == this.tiers.length) {
              p.tier = "All Ticket Tiers";
            } else {
              p.tiers.forEach((tier, key) => {
                let t = this.tiers.find((x) => x.value == tier);
                if (t) {
                  p.tier += t.label;
                  if (key + 1 < p.tiers.length) {
                    p.tier += ' , ';
                  }
                }
              });
            }
          }
          // if (p.tier_date && p.tier_date.$date) {
          //   if (p.tier_type === "Date" || p.tier_type === "Date and Time") {
          //     p.ticket_date = dayjs(+p.tier_date.$date.$numberLong).format(
          //       "MMM DD, YYYY"
          //     );
          //   }

          //   if (p.tier_type === "Date and Time") {
          //     p.ticket_time = dayjs(+p.tier_date.$date.$numberLong).format(
          //       "h:mm A"
          //     );
          //   }
          // }

          p.available_display = p.remaining + "/" + p.count;
          // makes it so the table callback will pass error check
          p.edit = true;
          p.categoryName = "Promo Discount";
          return p;
        });
        this.tableData = {
          title: "Promo Codes",
          columns: [
            {title: "Code", field: "name", width: "13%"},
            {title: "Ticket Tier", field: "tier", width: "32%"},
            {
              title: "Guest list items",
              field: "guest_list_count",
              width: "18%"
            },
            // { title: "Tier Date", field: "ticket_date", width: "10%" },
            // { title: "Tier Time", field: "ticket_time", width: "8%" },
            {
              title: "Discount",
              field: "value",
              width: "7%",
              isPromoDiscount: true
            },
            {title: "Available", field: "available_display", width: "7%"},
            {title: "Limit per user", field: "limitPerUser", width: "12%"},
            {
              title: "Start Date",
              field: "start_display",
              width: "12%",
              date: true
            },
            {
              title: "End Date",
              field: "end_display",
              width: "12%",
              date: true
            },
            {title: "Description", field: "description", width: "21%"},
            {title: "Active", field: "active", width: "4%", checkbox: true},
            {title: "Edit", field: "edit", width: "4%", icon: true}
          ],
          data: codes,
          searchable: ["name", "tier", "description"],
          callbacks: {
            active: this.updatePromoCode,
            edit: this.edit
          },
          icons: {
            edit: (row, column, library) => {
              library.add(faEdit);
              return "edit";
            }
          }
        };
      });

    this.multiselectOptions.push({
      "group-label": "Sections",
      options: sections
    });
    this.multiselectOptions.push({
      "group-label": "Foods",
      options: foods
    });
    this.multiselectOptions.push({
      "group-label": "Bottles",
      options: bottles
    });
  }
};
</script>

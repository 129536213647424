<template>
  <div class="promo-codes">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <div class="page-tabs">
          <tab-tote
            :tabs="navTabs"
            :value="navTabSelected"
            v-on:click="onClickNavTab"
          />
          <div class="divider" />
        </div>
        <router-view :url="url" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TabTote from "@/components/TabTote.vue";

export default {
  components: {
    TabTote,
    Breadcrumbs
  },
  props: {
    url: String
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("promo-codes")) {
        return 0;
      } else if (path.endsWith("bulk-discounts")) {
        return 1;
      } else if (path.endsWith("bundle-discounts")) {
        return 2;
      }
    }
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Promo Codes & Discounts", null]
      ],
      navTabs: ["Promo Codes", "Bulk Discounts", "Bundle Discounts"]
    };
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      const url = this.$route.params.url;
      if (index === 0 && !path.endsWith("promo-codes")) {
        this.$router.push(`/discounts/${url}/promo-codes`);
      } else if (index === 1 && !path.endsWith("bulk-discounts")) {
        this.$router.push(`/discounts/${url}/bulk-discounts`);
      } else if (index === 2 && !path.endsWith("bundle-discounts")) {
        this.$router.push(`/discounts/${url}/bundle-discounts`);
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Discounts");
    this.$store.commit(
      "setHelpVideoSrc",
      "https://youtube.com/embed/wgSiqHZnkZo"
    );
  }
};
</script>

<style>
</style>